<template>
  <div class="cs-container" style="background: white;">
    <div class="d-print-none flex items-center justify-end" style="margin-right:80px">
      <b-button variant="primary" @click="handlePrint">In Phiếu</b-button>
    </div>

    <header-form></header-form>

    <div class="d-flex align-items-center justify-content-center mx-auto w-90">
      <!-- <img class="cs-img col-6" src="@/assets/images/pages/AGTC-logo.png" /> -->
      <h6 class="title-color text-center">PHIẾU ĐỒNG THUẬN XÉT NGHIỆM SÀNG LỌC TIỀN SINH KHÔNG XÂM LẤN - NIPT</h6>
      <span class="close-icon d-print-none" @click="backToDetail()">&times;</span>
    </div>

    <div id="printOrderForm">
      <b-row>
        <b-col class="container-border">
          <div>
            <span class="cs-title">PHẦN 1: THÔNG TIN CHUNG</span>
            <div class="cs-flex-4 cs-border-b">
              <div class="col-4 p-0">
                <span class="cs-label-2 label">
                  Họ tên (IN HOA):
                  <span class="cs-content" v-html="form.full_name"></span>
                </span>
              </div>
              <div class="col-4 p-0">
                <span class="cs-label-2 label">
                  Ngày sinh:
                  <span class="cs-content">{{ handleFormatDate(form.date) }}</span>
                </span>
              </div>
              <div class="col-4 p-0">
                <span class="cs-label-2 label">
                  SĐT thai phụ:
                  <span class="cs-content" v-html="form.phone_number"></span>
                </span>
              </div>
            </div>
            <div class="cs-flex-4 cs-border-b">
              <div class="col-6 p-0">
                <span class="cs-label-2">
                  Địa chỉ:
                  <span class="cs-content" v-html="form.patient_address"></span>
                </span>
              </div>
              <div class="col-6 p-0">
                <span class="cs-label-2">
                  Email:
                  <span class="cs-content" v-html="form.patient_email"></span>
                </span>
              </div>
            </div>
            <div class="cs-flex-4 cs-border-b">
              <div class="col-6 p-0">
                <span class="cs-label-2">
                  Bác sĩ:
                  <span class="cs-content" v-html="form.doctor"></span>
                </span>
              </div>
              <div class="col-6 p-0">
                <span class="cs-label-2">
                  Phòng khám/BV:
                  <span class="cs-content" v-html="form.hospital"></span>
                </span>
              </div>
            </div>
            <div class="blood-day">
              <span class="cs-content-1 label">
                <strong>Ngày lấy máu:</strong>
                <span
                  class="cs-content-extra"
                >{{ handleFormatDate(form.sample_collection_date_time) }}</span>
              </span>
              <span class="cs-content-2 label">
                <strong>Nơi lấy máu:</strong>
                <span class="cs-content-extra" v-html="form.place"></span>
              </span>
              <span class="cs-content-3 label">
                <strong>Mã XN:</strong>
                <span class="cs-content-extra" v-html="form.factory_code"></span>
              </span>
            </div>
          </div>
          <!-- PATIENT INFORMATION -->
          <!-- REFERRING PHYSICIAN INFORMATION -->
          <div>
            <span class="cs-title">PHẦN 2: THÔNG TIN LÂM SÀNG</span>
            <div class="cs-flex-2 cs-border-b">
              <span class="cs-label text-danger">Đơn thai*:</span>

              <div class="d-flex cs-content">
                <div class="mr-1 cs-checkbox" :set="isOne=form.twins===1">
                  <input
                    v-model="isOne"
                    id="hospital"
                    name="hospital"
                    type="checkbox"
                    @change="(e)=>e.target.checked ? form.twins = 1 : form.twins = null"
                  />
                  <label>Có</label>
                </div>
                <div class="mr-1 cs-checkbox" :set="isTwo= form.twins===2">
                  <input
                    v-model="isTwo"
                    id="hospital"
                    name="hospital"
                    type="checkbox"
                    @change="(e)=>e.target.checked ? form.twins = 2 : form.twins = null"
                  />
                  <label>Không</label>
                </div>
              </div>
              <span class="cs-label text-danger">Song thai tiêu biến*:</span>
              <div class="d-flex cs-content">
                <div class="mr-1 cs-checkbox" :set="isFirst=form.crazy_twins===1">
                  <input
                    v-model="isFirst"
                    id="hospital"
                    name="hospital"
                    type="checkbox"
                    @change="(e)=>e.target.checked ? form.crazy_twins = 1 : form.crazy_twins = null"
                  />
                  <label>Có</label>
                </div>
                <div class="mr-1 cs-checkbox" :set="isSecond= form.crazy_twins===2">
                  <input
                    v-model="isSecond"
                    id="hospital"
                    name="hospital"
                    type="checkbox"
                    @change="(e)=>e.target.checked ? form.crazy_twins = 2 : form.crazy_twins = null"
                  />
                  <label>Không</label>
                </div>
              </div>
              <span class="cs-label text-danger">Thai IVF*:</span>
              <div class="d-flex cs-content">
                <div class="mr-1 cs-checkbox" :set="isHospital=form.ivf_fetus===1">
                  <input v-model="isHospital" id="hospital" name="hospital" type="checkbox" />
                  <label>Có</label>
                </div>
                <div class="mr-1 cs-checkbox" :set="isSelfPay= form.ivf_fetus===2">
                  <input v-model="isSelfPay" id="self-pay" name="self-pay" type="checkbox" />
                  <label>Không</label>
                </div>
              </div>
            </div>
            <div class="cs-flex-3 cs-border-b p-0">
              <span class="cs-label-3 label text-danger">
                Tuổi thai* (>= 9):
                <span class="cs-content" v-html="form.age"></span>
                <span class="ml-1">tuần</span>
              </span>



              <span class="cs-label-3 label text-danger">Các mục đánh dâu * là thông tin bắt buộc</span>
            </div>
            <div class="cs-flex-3 cs-border-b">
              <span>Xét nghiệm NIPT KHÔNG được khuyến cáo thực hiện trên song thai, song thai tiêu biến (bao gồm cả trường hợp IVF đặt nhiều phôi nhưng chỉ 01 phôi phát triển), NT > 3,5mm, nguy cơ > 1/50, thai có siêu âm bất thường</span>
            </div>
            <div class="cs-flex-3 cs-border-b">
              <span class="cs-label-extra label">
                Ngày siêu âm:
                <span class="cs-content">{{ handleFormatDate(form.x_ray_date) }}</span>
              </span>
              <span class="cs-label-extra label">
                Chiều dài đầu mông (CRL):
                <span class="cs-content" v-html="form.general_information1"></span>
              </span>
              <span class="cs-label-extra label">
                Độ mờ da gáy (NT):
                <span class="cs-content" v-html="form.general_information2"></span>
              </span>
            </div>
            <div class="cs-flex-3 cs-border-b">
              <span class="cs-label-extra label">
                Nguy cơ sàng lọc trước sinh:
                <span
                  class="cs-content"
                  v-html="form.general_information3"
                ></span>
              </span>
              <span class="cs-label-extra label">
                Chiều cao thai phụ:
                <span class="cs-content" v-html="form.general_information4"></span>
              </span>
              <span class="cs-label-extra label">
                Cân nặng thai phụ:
                <span class="cs-content" v-html="form.general_information5"></span>
              </span>
            </div>
            <div class="cs-flex-2">
              <span class="cs-label-extra text-danger">Sàng lọc 09 bệnh di truyền lặn đơn gen*:</span>
              <div class="d-flex cs-content">
                <div class="mr-1 cs-checkbox" :set="isYes=form.tick===1">
                  <input
                    v-model="isYes"
                    id="hospital"
                    name="hospital"
                    type="checkbox"
                    @change="(e)=>e.target.checked ? form.tick = 1 : form.tick = null"
                  />
                  <label>Có</label>
                </div>
                <div class="mr-1 cs-checkbox" :set="isNo=form.tick===2">
                  <input
                    v-model="isNo"
                    id="self-pay"
                    name="self-pay"
                    type="checkbox"
                    @change="(e)=>e.target.checked ? form.tick = 2 : form.tick = null"
                  />
                  <label>Không</label>
                </div>
                <span class="ml-2" style="display: flex; align-items: end;gap: 3px;">
                  (Áp dụng khi đăng ký xét nghiệm 
                  <b> NIPT5 </b> và 
                  <b> NIPT24</b>)
                </span>
              </div>
            </div>
          </div>
          <!-- REFERRING PHYSICIAN INFORMATION -->
          <!-- ACCOUNT INFORMATION -->
          <div>
            <span class="cs-title">PHẦN 3: THỎA THUẬN SỬ DỤNG DỊCH VỤ</span>
            <div class="cs-flex-3">
              <span>
                <strong>A.Tôi lựa chọn xét nghiệm (tích vào ô chọn):</strong>
              </span>
            </div>
            <div class="mr-1 cs-checkbox-1 text-danger" :set="isNipt24=form.nipt_24===1">
              <input
                v-model="isNipt24"
                id="nipt-24"
                name="nipt-24"
                type="checkbox"
                @change="(e)=>e.target.checked ? form.nipt_24 = 1 : form.nipt_24 = null"
              />
              <label class="text-danger">Xét nghiệm NIPT24:</label>
              <label>khảo sát nguy cơ lệch bội của tất cả các nhiễm sắc thể - NST (22 NST thường và cặp NST giới tính, không khảo sát XYY) ở thai nhi</label>
            </div>
            <div class="mr-1 cs-checkbox-1 text-danger" :set="isNipt5=form.nipt_5===2">
              <input
                v-model="isNipt5"
                id="nipt-5"
                name="nipt-5"
                type="checkbox"
                @change="(e)=>e.target.checked ? form.nipt_5 = 2 : form.nipt_5 = null"
              />
              <label class="text-danger">Xét nghiệm NIPT5:</label>
              <label>khảo sát nguy cơ lệch bội của 5 NST 21, 13, 18 và cặp NST giới tính (trisomy 21, trisomy 18, trisomy 13, XO, XXX, XXY, không khảo sát XYY)</label>
            </div>
            <div class="mr-1 cs-checkbox-1 text-danger" :set="isNipt4=form.nipt_4===3">
              <input
                v-model="isNipt4"
                id="nipt-4"
                name="nipt-4"
                type="checkbox"
                @change="(e)=>e.target.checked ? form.nipt_4 = 3 : form.nipt_4 = null"
              />
              <label class="text-danger">Xét nghiệm NIPT4:</label>
              <label>khảo sát nguy cơ mắc hội chứng tam NST 21 (trisomy 21), tam NST 18 (trisomy 18), tam NST 13 (trisomy 13), XO (monosomy X) ở thai nhi</label>
            </div>
            <div class="mr-1 cs-checkbox-1 text-danger" :set="isNipt3=form.nipt_3===4">
              <input
                v-model="isNipt3"
                id="nipt-3"
                name="nipt-3"
                type="checkbox"
                @change="(e)=>e.target.checked ? form.nipt_3 = 4 : form.nipt_3 = null"
              />
              <label class="text-danger">Xét nghiệm NIPT3:</label>
              <label>khảo sát nguy cơ mắc 3 hội chứng Tam NST 21 (trisomy 21), 18 (trisomy 18), 13 (trisomy 13) ở thai nhi</label>
            </div>
            <div class="mr-1 cs-checkbox-1 text-danger" :set="isNipt=form.nipt===5">
              <input
                v-model="isNipt"
                id="nipt"
                name="nipt"
                type="checkbox"
                @change="(e)=>e.target.checked ? form.nipt = 5 : form.nipt = null"
              />
              <label class="text-danger">Xét nghiệm NIPT..........</label>
            </div>
            <div class="cs-flex-extra">
              <span>
                <strong>B. Nguyên lý của xét nghiệm</strong>
              </span>
              <span class="cs-content" v-html="form.acc_contact_no"></span>
              <span>Xét nghiệm NIPT tìm kiếm những bất thường của NST bằng cách phân tích DNA (vật liệu di truyền) trong mẫu máu của thai phụ. Mẫu máu của thai phụ. Mẫu máu của thai phụ có chứa các phần tử DNA tự do được phóng thích từ nhau thai. Bằng cách sử dụng kỹ thuật giải trình tự thế hệ mới, các phân tử DNA được đếm để xác định lượng DNA phóng thích từ mỗi NST, sau đó phần mềm phân tích chuyên biệt được sử dụng để xác định nguy cơ của các bất thường NST</span>
            </div>
            <div class="cs-flex-extra">
              <span>
                <strong>C. Điều kiện để làm xét nghiệm</strong>
              </span>
              <div class="d-flex left">
                <li>Tuổi thai ít nhất 9 tuần tuổi</li>
                <li>Thai đơn xác định bằng siêu âm</li>
              </div>
              <div class="d-flex list">
                <li>Thai phụ không truyền máu, không ghép tủy hoặc cơ quan nội tạng, không bị ung thư</li>
              </div>
            </div>
            <div class="cs-flex-3">
              <span>
                <strong>D. Hạn chế của xét nghiệm</strong>
              </span>
            </div>
            <li
              class="list-2"
            >Xét nghiệm NIPT chỉ có khả năng phát hiện các tam nhiễm thường được khảo sát và lệch bội NST giới tính (không bao gồm XYY) và không nhằm mục đích phát hiện thể khảm hoặc tam bội một phần hoặc UPD (Uniparental disomy) hoặc bất thường cấu trúc NST</li>
            <li
              class="list-2"
            >Xét nghiệm phát hiện bất thường số lượng NST dựa trên các DNA tự do được phóng thích từ tế bào nhau thai. Trong một vài trường hợp hiếm gặp (1%), tế bào nhau thai bị tam bội nhưng thai bình thường hoặc ngược lại; hiện tượng này sẽ dẫn đến kết quả xét nghiệm bị sai lệch</li>
            <li
              class="list-2"
            >Xét nghiệm này là xét nghiệm sàng lọc, nên kết quả nguy cơ cao (dương tính hoặc nghi ngờ) CẦN phải được kiểm tra lại bằng các xét nghiệm chẩn đoán</li>
            <li
              class="list-2"
            >Kết quả xét nghiệm nguy cơ thấp (âm tính) không hoàn toàn loại bỏ khả năng thai nhi bị các bất thường di truyền khác trên NST.</li>
            <li
              class="list-2"
            >Độ nhạy và độ đặc hiệu cho tam nhiễm hiếm gặp (NST 1 đến 12, 14 đến 17, 19, 20 và 22) vẫn chưa được xác định do số ca dương tính thấp và vẫn chưa được thống kê bởi các nghiên cứu trên thế giới. Tam nhiễm hiếm gặp có thể có biểu hiện bệnh biến động ở mức độ khác nhau và khó xác định</li>
            <li class="list-2">
              Độ tin cậy của xét nghiệm sẽ KHÔNG đảm bảo trong các trường hợp sau:
              <br />- Thai phụ được điều trị ung thư hay truyền máu trong ba (03) tháng trước khi thực hiện xét nghiệm.
              <br />- Thai phụ mang lệch bội NST hoặc bất thường cấu trúc NST, bao gồm dạng thể khảm (maternal mosaicism). Hiện tượng này được quan sát khá phổ biến trong phân tích lệch bội NST giới tính.
            </li>
            <div class="cs-flex-extra">
              <span>
                <strong>E. Nghiên cứu</strong>
              </span>
              <span class="cs-content" v-html="form.acc_email"></span>
              <span>Chúng tôi có thể sử dụng mẫu dư và các thông tin đã loại bỏ danh tính của thai phụ vào mục đích nghiên cứu. Các nghiên cứu này có thể được công bố trên các tạp chí khoa học hoặc sử dụng để phát triển các sản phẩm và dịch vụ của chúng tôi. Thai phụ sẽ không được thông báo hoặc trả tiền cho các hoạt động nghiên cứu này.</span>
            </div>
            <div class="cs-flex-3"></div>
          </div>
          <!-- ACCOUNT INFORMATION -->
          <!-- BILLING INFORMATION -->
          <div class="cs-width-full ">
            <span class="cs-title">PHẦN 4: ĐỒNG Ý CỦA THAI PHỤ VÀ BÁC SĨ</span>
            <div class="d-flex cs-border-l cs-border-t">
              <div class="col-6 cs-border-r">
                <div>
                  <span
                    class="cs-text-md container-border"
                  >Thai phụ đã đọc, hiểu rõ và đồng ý với hạn chế của xét nghiệm và các nội dung khác được ghi trong trang 1 và 2 của phiếu này</span>
                </div>
              </div>
              <div class="col-6 cs-border-r">
                <div>
                  <span
                    class="cs-text-md container-border"
                  >Thai phụ đã được giải thích về hạn chế của xét nghiệm được ghi ở trong phần 3 của phiếu này</span>
                </div>
              </div>
            </div>
          </div>
          <div class="cs-width-full">
            <div class="d-flex cs-border-l cs-border-t">
              <div class="col-6 cs-border-r">
                <div>
                  <span class="cs-text-md container-border">
                    Tôi đồng ý làm xét nghiệm
                    <span class="text-danger">NIPT</span>*
                    <span class="cs-content" v-html="form.agreement"></span>
                  </span>
                </div>
              </div>
              <div class="col-6 p-0 cs-border-r">
                <div>
                  <span class="cs-text-md container-border"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="cs-width-full">
            <div class="d-flex cs-border-l">
              <div class="col-6 cs-border-r">
                <div class="mr-1 cs-checkbox-2 m-0">
                  <span
                    class="cs-text-md container-border text-danger"
                  >Kèm xét nghiệm 9 gen bệnh ẩn:</span>
                  <div class="d-flex cs-content">
                    <div class="mr-1 cs-checkbox" :set="isYay=form.twins_checkbox===1">
                      <input
                        v-model="isYay"
                        id="yes"
                        name="yes"
                        type="checkbox"
                        @change="(e)=>e.target.checked ? form.twins_checkbox = 1 : form.twins_checkbox = null"
                      />
                      <label>Có</label>
                    </div>
                    <div class="mr-1 cs-checkbox" :set="isNah=form.twins_checkbox===2">
                      <input
                        v-model="isNah"
                        id="no"
                        name="no"
                        type="checkbox"
                        @change="(e)=>e.target.checked ? form.twins_checkbox = 2 : form.twins_checkbox = null"
                      />
                      <label>Không</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 p-0 cs-border-r">
                <div>
                  <span class="cs-text-md container-border"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="cs-width-full">
            <div class="d-flex col-6 cs-border-l"></div>
          </div>
          <div class="cs-width-full">
            <div class="d-flex cs-border-l">
              <div class="col-6 p-0 cs-border-r">
                <div class="cs-flex-3 mb-12"></div>
              </div>
              <div class="col-6 p-0 cs-border-r">
                <div class="cs-flex-3"></div>
              </div>
            </div>
          </div>
          <div class="cs-width-full">
            <div class="d-flex cs-border-l">
              <div class="col-6 cs-border-r">
                <div class="cs-flex-3 p-0">
                  <span class="cs-text-md p-0">Thai phụ ký, ghi rõ họ tên (CHỮ IN HOA):</span>
                </div>
              </div>
              <div class="col-6 cs-border-r">
                <div class="cs-flex-3 p-0">
                  <span class="cs-text-md container-border">Bác sĩ ký, ghi rõ họ tên</span>
                </div>
              </div>
            </div>
          </div>
          <div class="cs-width-full">
            <div class="d-flex cs-border-l">
              <div class="col-6 p-0 cs-border-r">
                <div class="cs-flex-3 cs-border-b" style="padding-left: 1rem;">
                  <span class="just-title label">Ngày:</span>
                  <span>{{ handleFormatDate(form.signing_day_patient) }}</span>
                </div>
              </div>
              <div class="col-6 p-0 cs-border-r">
                <div class="cs-flex-3 cs-border-b" style="padding-left: 1rem;">
                  <span class="just-title label">Ngày:</span>
                  <span>{{ handleFormatDate(form.signing_day_doctor) }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- BILLING INFORMATION -->
          <!-- SPECIMEN INFORMATION -->
          <div class="mt-2 html2pdf__page-break">
            <span class="cs-title">1. CÁC ĐIỀU KHOẢN DỊCH VỤ</span>
            <div class="d-flex">
              <strong>1.1</strong>
              <span
                style="margin-left: 5px"
              >Mẫu được thực hiện XN tại Viện Di Truyền Y học (186-188 Nguyễn Duy Dương, P.3, Q.10, Tp.HCM) theo qui trình "Xét nghiệm tiền sinh không xâm lấn - NIPT" đã được phê duyệt và cấp phép của Sở Y tế thành phố Hồ Chí Minh</span>
            </div>
            <div
              style="margin-left: 25px"
            >ứng dụng trong tầm soát trước sinh (GPHDKCB: 06939/HCM-GPHĐ)</div>
          </div>
          <div class="d-flex" style="margin-top: 5px">
            <strong>1.2</strong>
            <span style="margin-left: 5px">Xét nghiệm NIPT được thực hiện khi thai từ 9 tuần.</span>
          </div>
          <div class="d-flex" style="margin-top: 5px">
            <strong>1.3</strong>
            <span
              style="margin-left: 5px"
            >Không khuyến khích xét nghiệm NIPT cho những trường hợp bất thường siêu âm vì hạn chế của NIPT trong phát hiện các rối loạn cấu trúc nhiễm sắc thể, các đột biến gen và tình trạng khảm bánh rau. Những trường hợp này nếu kết quả NIPT âm tính (không phát hiện bất thường), vẫn cần xét nghiệm ối vì chưa loại trừ được các rối loạn trên.</span>
          </div>
          <div class="mt-2">
            <span class="cs-title">2. CHÍNH SÁCH HỖ TRỢ</span>
            <div class="d-flex">
              <strong>2.1</strong>
              <span
                style="margin-left: 5px"
              >Các thai phụ đăng ký các gói xét nghiệm NIPT 5 hoặc NIPT 24 được lựa chọn tầm soát miễn phí các bệnh di truyền lặn đơn gen phổ biến (đánh dấu đồng ý nếu sàng lọc)</span>
            </div>
            <div
              style="margin-left: 25px; margin-top: 10px"
            >- Tan máu bẩm sinh (Thalassemia thể Alpha và Beta)</div>
            <div
              style="margin-left: 25px; margin-top: 10px"
            >- Rối loạn chuyển hóa tăng Galactose máu</div>
            <div style="margin-left: 25px; margin-top: 10px">- Phenylketon niệu</div>
            <div style="margin-left: 25px; margin-top: 10px">- Thiếu hụt men G6PD</div>
            <div style="margin-left: 25px; margin-top: 10px">- Vàng da ứ mặt do thiếu hụt men Citrin</div>
            <div
              style="margin-left: 25px; margin-top: 10px"
            >- Rối loạn phát triển giới tính do thiếu hụt men 5-alpha reductase</div>
            <div
              style="margin-left: 25px; margin-top: 10px"
            >- Bệnh Pompe (rối loạn dự trữ glycogen loại 2)</div>
            <div
              style="margin-left: 25px; margin-top: 10px; margin-bottom: 10px;"
            >- Bệnh Wilson (rối loạn chuyển hóa đồng)</div>
          </div>
          <span>Nếu thai phụ có kết quả dương tính, và chồng thực hiện xét nghiệm tính phí (4 gói) có kết quả dương tính thì hỗ trợ xét nghiệm miễn phí cho con</span>
          <div class="mt-2">
            <div class="d-flex">
              <strong>2.2</strong>
              <span style="margin-left: 5px">
                <strong>Dịch vụ hỗ trợ chẩn đoán xác minh qua dịch ối</strong>
              </span>
            </div>
            <div
              style="margin-left: 25px; margin-top: 10px"
            >- Nếu kết quả xét nghiệm NIPT "Dương tính" hoặc "Nghi ngờ", thai phụ được hỗ trợ xét nghiệm chẩn đoán xác định qua tế bào ối bằng một trong các dịch vụ sau: Hỗ trợ xét nghiệm ối (Chọc hút và phân tích bằng QF-PCR hoặc NST đồ tại các bệnh viện) tối đa 3.500.000 VNĐ hoặc phân tích mẫu dịch ối miễn phí bằng kỹ thuật CNV tại viện Di truyền Y học.</div>
            <div
              style="margin-left: 25px; margin-top: 10px"
            >- Nếu kết quả xét nghiệm NIPT "Âm tính" nhưng sau đó siêu âm có bất thường, thai phụ được miễn phí xét nghiệm CNV và G4500 từ dịch ối để tìm nguyên nhân bất thường tại viện di truyền Y học.</div>
          </div>
          <div class="mt-2">
            <div class="d-flex">
              <strong>2.3</strong>
              <span style="margin-left: 5px">
                <strong>Chính sách bảo hiểm(áp dụng với xét nghiệm NIPT)</strong>
              </span>
            </div>
            <div
              style="margin-left: 25px; margin-top: 10px"
            >- Kết quả NIPT bình thường, thai phụ sinh con bị mắc hội chứng Down (trisomy 21), hội chứng Patau(trisomy 13), hội chứng Edwards(trisomy 18) dạng thuần: hỗ trợ cho gia đình 200 triệu VNĐ. Các trường hợp được khuyến cáo xét nghiệm ối nhưng không thực hiện, các trường hợp không siêu âm thai, khám thai định kỳ nếu sinh con bệnh sẽ không được hưởng chính sách bảo hiểm này.</div>
            <div
              style="margin-left: 25px; margin-top: 10px"
            >- Kết quả NIPT5 hoặc NIPT24 bình thường, thai phụ được phát hiện trước sinh thai bị mắc hội chứng Down(trisomy 21), hội chứng Patau(trisomy 13), hội chứng Edwards(trisomy 18) dạng thuần: hỗ trợ cho gia đình 30 triệu VNĐ.</div>
            <div class="tiny-text">
              <h4>
                <strong>Chính sách này áp dụng từ ngày 01/03/2023 và có giá trị tới khi có thông báo tiếp theo</strong>
              </h4>
            </div>
            <div class="consultant" style="text-decoration: underline;">
              <h3>
                <pre style="background-color: white;"><strong>HỖ TRỢ TƯ VẤN XÉT NGHIỆM:<span class="text-primary">0383226165</span>       HỖ TRỢ TƯ VẤN DI TRUYỀN:<span class="text-primary">0906186465</span></strong></pre>
              </h3>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
  
  <script>
import { formatDate } from "@/@core/utils/filter";
import { mapState } from "vuex";
const HeaderForm = () => import("@/views/PrintOrderForm/HeaderForm.vue");

const DEFAULT_FORM = {
  experiment_day: null,
  tick: null,
  general_information1: "",
  general_information2: "",
  general_information3: "",
  general_information4: "",
  general_information5: "",
  age: "",
  week: "",
  hospital: "",
  phone_number: "",
  patient_address: "",
  patient_email: "",
  full_name: "",
  doctor: "",
  place: "",
  factory_code: "",
  sample_type: "",
  identity_card_number: "",
  medical_record_no: null,
  gender: null,
  urgency: null,
  other_instruction: "",
  physician_name: "",
  contact_number: null,
  email: "",
  twins: null,
  crazy_twins: null,
  ivf_fetus: null,
  nsr_no: null,
  acc_name: "",
  acc_contact_no: null,
  acc_address: "",
  acc_email: "",
  paid_by: null,
  billed_to: "",
  bill_acc_no: null,
  bill_address: "",
  bill_phone: null,
  bill_email: "",
  sample_collection_date_time: null,
  sample_id: null,
  sample_type: null,
  fixative: null,
  other_fixative: "",
  body_site: "",
  official_stamp: "",
  solid_tumour_type: [],
  solid_tumour_stage: null,
  stage_relapse: "",
  haematological_tumour_type: [],
  additional_notes: "",
  test_selection: [],
  other_test_description: "",
  other_test_info: {},
  authorised_content: "",
  authorised_status: null,
  authorised_name: "",
  authorised_date: null,
  authorised_reject_reason: "",
  marketing_content: "",
  marketing_name: "",
  marketing_date: null,
  marketing_remark: "",
  patient_name: "",
  patient_id: null,
  completed_by: "",
  legal_guardian_name: "",
  legal_guardian_ic: "",
  legal_guardian_date: null,
  legal_guardian_signature: "",
  legal_guardian_confirm: "",
  counsellor_name: "",
  counsellor_name_2: "",
  counsellor_date: "",
  counsellor_signature: "",
  counsellor_confirm: "",
  list_primary: "",
  relapse: false,
  sample_requirement: [],
  shipping_instructions: "",
  office_content: "",
  // This line is about the NIPT experiment
  nipt_24: null,
  nipt_5: null,
  nipt_4: null,
  nipt_3: null,
  nipt: null,
  // This line is about the NIPT experiment
  // The name of doctors and the mothers
  mother_name: "",
  doctor_name: "",
  // The name of doctors and the mothers
  // Agreement on both sides
  agreement: "",
  twins_checkbox: "",
  // Agreement on both sides
  // The name of doctors and the mothers
  mother_name: "",
  doctor_name: "",
  // The name of doctors and the mothers
  // Signing agreement day
  signing_day_patient: null,
  signing_day_doctor: null
  // Signing agreement day
};

export default {
  name: "PrintOrderForm",
  components: {
    HeaderForm
  },
  data() {
    return {
      form: { ...DEFAULT_FORM },
      DEFAULT_FORM
    };
  },
  computed: {
    ...mapState({
      orders: state => state.orders
    })
  },
  watch: {
    $route() {
      if (this.$route.params.id) {
        this.handleMapData();
      }
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.handleMapData();
    }
  },
  methods: {
    handlePrint() {
      document.title = "Requisition-form";
      window.print();
    },
    handleMapData() {
      this.form = this.orders.requisitionFormV2 || { ...DEFAULT_FORM };
    },
    backToDetail() {
      document.title = "HODO Partner";
      this.$router.replace({
        name: "orderDetail",
        params: {
          id: this.orders?.viewDetail?.id
        },
        query: {
          step: 1
        }
      });
    },
    handleFormatDate(date) {
      if (!date) return "";
      return moment(date).format("DD-MM-YYYY");
    }
  }
};
</script>
  
<style lang="scss" scoped>
.w-90 {
  width: 90%;
}
.cs-container {
  position: relative;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;

  * {
    color: black;
    font-family: "Times New Roman", "Tinos", Times, serif !important;
  }

  #printOrderForm {
    margin: auto;
    // margin-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }

  h2 {
    // color: black;
    font-size: 28px;
  }
}

.space-letter {
  margin-top: 10px;
  gap: 5px;
}

.container-border {
  // border: 1px solid #5c5c5c;
  padding: 0;
  width: 50%;
}

.cs-title {
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 6px;
  background-color: #0569c5;
  color: #ffffff;
}

.cs-flex {
  display: flex;
  // gap: 20rem;
  gap: 8px;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
}

.cs-flex-2 {
  display: flex;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
}

// .part-four {
//   margin-top: 30rem;
// }
.cs-flex-3 {
  display: flex;
  // flex-direction: row;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
}

.cs-flex-4 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
}
.cs-flex-extra {
  display: flex;
  flex-direction: column;
  padding: 6px;
  box-sizing: border-box;
}

.left {
  gap: 200px;
  list-style-type: circle;
}

.cs-label {
  width: 150px;
}

.cs-label-extra {
  width: 300px;
}

.letter {
  display: flex;
  margin-left: 7px;
  box-sizing: border-box;
  align-items: center;
}
.cs-label-2 {
  width: 150px;
  font-weight: bold;
}

.just-title {
  width: 150px;
}

.cs-label-3 {
  width: 260px;
  margin-right: 60px;
  padding: 6px;
}

.cs-label-4 {
  margin-left: 220px;
}

.order {
  // gap: 200px;
  list-style-type: circle;
  gap: 150px;
}

.list {
  list-style-type: circle;
}

.list-2 {
  list-style-type: circle;
  margin-left: 9px;
}

.cs-border-b {
  border-bottom: 1px solid #5c5c5c;
}
.cs-border-t {
  border-top: 1px solid #5c5c5c;
}

.cs-border-l {
  border-left: 1px solid #5c5c5c;
}

.cs-border-r {
  border-right: 1px solid #5c5c5c;
}

.cs-width-full {
  width: 100% !important;
}

.cs-font-bold {
  font-weight: bold;
}

.cs-checkbox {
  pointer-events: none;
  display: flex;
  align-items: center;
  margin-top: 5px;

  input {
    width: 18px;
    height: 18px;
  }

  input[type="checkbox"] {
    accent-color: #20419b;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-left: 6px;
  }
}

.cs-checkbox-1 {
  pointer-events: none;
  display: flex;
  align-items: center;
  margin-left: 6px;
  margin-top: 6px;
  input {
    width: 18px;
    height: 18px;
    // margin-left: 50px;
  }

  input[type="checkbox"] {
    accent-color: #20419b;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-left: 6px;
  }
}

.cs-checkbox-2 {
  pointer-events: none;
  display: flex;
  align-items: center;
  margin-left: 6px;
  margin-top: 6px;
  // input {
  //   width: 18px;
  //   height: 18px;
  //   margin-left: 50px;
  // }

  input[type="checkbox"] {
    accent-color: #20419b;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-left: 6px;
  }
}

.cs-content {
  flex: 1;
}

.blood-day {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.cs-content-1 {
  display: flex;
  flex-direction: row;
}

.cs-content-2 {
  margin-left: 265px;
}
.cs-content-3 {
  margin-left: 290px;
}

.cs-content-extra {
  margin-left: 5px;
  font-weight: bold;
}
.min-checkbox {
  min-width: 120px;
}
.box-content {
  display: block;
  padding: 6px;
  min-height: 120px;
}
.html2pdf__page-break {
  page-break-before: always;
}

.cs-padding {
  padding: 6px;
}

.cs-border {
  border: 1px solid #5c5c5c;
}

.content-none {
  width: 100%;
  border-bottom: 2px dashed;
}

.cs-text-sm {
  font-size: 11px;
  font-weight: 500;
}

.cs-text-md {
  font-size: 16px;
}

.cs-list {
  display: flex;
  gap: 12px;
}

.cs-ol {
  li {
    position: relative;
    padding-left: 10px;
    margin-left: 15px;
  }

  li:before {
    position: absolute;
    left: -2px;
  }
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 20px;
  color: black;
  font-size: 40px;
  cursor: pointer;
}

.cs-img {
  width: 300px;
}

.title-color {
  color: #0569c5;
  font-size: 24px !important;
}

.tiny-text {
  text-align: center;
  margin-top: 10px;
}

.consultant {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.left-side {
  margin-left: 30px;
}

.right-side {
  margin-left: 60px;
}

@media print {
  body,
  html {
    height: 100vh;
    background-color: #fff !important;
  }
  .text-danger, .title-color {
    color: black !important;
  }
  input[type="checkbox"] {
    accent-color: #20419b;
  }

  .hidden-print {
    border: none;
    outline: none;
    background: transparent;
  }

  #BrowserPrintDefaults {
    display: none;
  }

  #printOrderForm {
    max-width: 1000px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .html2pdf__page-break {
    page-break-before: always;
  }

  @page {
    margin: 0;
  }
}
</style>